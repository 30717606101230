<template>
  <div>
    <b-button
      variant="primary"
      @click="informationModal"
      :disabled="disabled.edit"
      class="ml-2"
      >Confirm Payment</b-button
    >
    <b-modal
      v-model="showModal.information"
      centered
      no-close-on-backdrop
      scrollable
      title="Confirm Payment"
      id="confirm_payment"
      ref="modal"
      size="lg"
    >
      <b-overlay :show="loading">
        <b-row class="flex-col" style="min-height: 300px">
          <b-col md="12" class="my-1">
            <b-form-group
              class="invoice_total_amount"
              label-for="invoice_total_amount"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Total Amount"
            >
              <b-form-input
                v-model="invoice_total_amount"
                type="text"
                :placeholder="$t('page.please_choose_input')"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12" class="my-1">
            <b-form-group
              class="payment_date"
              label-for="payment_date"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Payment Date"
            >
              <b-input-group>
                <template v-slot:append>
                  <div
                    v-if="
                      save.payment_date != null &&
                      save.payment_date != '' &&
                      (payment_date == '' || payment_date == null)
                    "
                    class="datepicker-x"
                    @click="initialTime(save, 'payment_date')"
                  >
                    <b-icon-x font-scale="1.45"></b-icon-x>
                  </div>
                  <b-input-group-text>
                    <b-icon-calendar2-date></b-icon-calendar2-date>
                  </b-input-group-text>
                </template>
                <b-form-datepicker
                  v-model="save.payment_date"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  }"
                  :hide-header="true"
                  placeholder="No date selected"
                  locale="de"
                  :disabled="payment_date != '' && payment_date != null"
                ></b-form-datepicker>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-overlay>
      <template v-slot:modal-footer="{ close }">
        <b-button
          variant="primary"
          @click="submit()"
          :disabled="
            disabled.submit || (payment_date != '' && payment_date != null)
          "
        >
          Submit
        </b-button>
        <b-button variant="secondary" @click="close()">
          {{ $t('page.cancel') }}
        </b-button>
      </template>
    </b-modal>
    <b-modal
      v-model="showModal.delete"
      centered
      no-close-on-backdrop
      scrollable
      title="Please Confirm"
      id="please_confirm"
      ref="modal"
    >
      <b-overlay :show="loading2">
        Are you sure to update this invoice payment date?
      </b-overlay>

      <template v-slot:modal-footer="{ close }">
        <b-button
          variant="primary"
          @click="confirm()"
          :disabled="disabled.confirm"
        >
          Confirm
        </b-button>
        <b-button variant="secondary" @click="close()">
          {{ $t('page.cancel') }}
        </b-button>
      </template></b-modal
    >
  </div>
</template>
  <script>
import { mapState } from 'vuex';
import apiBus from '@/common/apiBus/index';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
export default {
  name: 'confirmPayment',
  data: function () {
    return {
      tabs: 0,
      showModal: {
        information: false,
        delete: false
      },
      disabled: {
        delete: false,
        edit: false,
        view: false,
        submit: false,
        confirm: false
      },
      save: {
        payment_date: null
      },
      loading: false,
      loading2: false
    };
  },
  props: ['id', 'initFn', 'status', 'payment_date', 'invoice_total_amount'],
  methods: {
    //   删除
    initialTime(obj, name) {
      obj[name] = null;
    },

    informationModal() {
      this.showModal.information = true;
      this.loading = false;
      this.disabled.submit = false;
      this.save.payment_date = this.payment_date;
    },
    submit() {
      if (this.save.payment_date == null || this.save.payment_date == '') {
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 5, //初始提示作用于定时器
          alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
          alertText: 'Fields marked with * are required!' // 提示内容
        });
        return false;
      }
      this.loading = true;
      this.disabled.submit = true;
      let data = {
        id: this.id,
        payment_date: this.save.payment_date
      };

      apiBus.uploadInvoice
        .updatePurchaseInvoicePaymentDate(data)
        .then((data) => {
          console.log(data);
          this.showModal.information = false;
          this.loading = false;
          this.disabled.submit = false;
          this.initFn();
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully!' // 提示内容
          });
        })
        .catch((error) => {
          // this.showModal.information = false;
          this.loading = false;
          this.disabled.submit = false;
          // this.initFn();
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },
    init() {}
  },
  watch: {},
  computed: {
    ...mapState({
      baseConfig: (state) => state.baseconfig.baseconfig
    })
  },
  mounted() {
    this.init();
  }
};
</script>
  <style scoped>
.flex-col {
  display: flex;
  flex-direction: column;
}
</style>
  